<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
    <div
      v-if="dictionary && Object.keys(dictionary).length > 0"
      :class="
        errors.length > 0
          ? 'columns is-variable is-6 is-multiline v-field v-error'
          : 'columns is-variable is-6 is-multiline'
      "
    >
      <div class="column ym-column is-4" style="align-items: baseline">
        <div class="ym-label">
          <label for="f_company_full">{{ text }}</label>
          <span v-if="errors.length > 0" class="help is-danger">{{
            errors[0]
          }}</span>
        </div>
      </div>
      <div class="column ym-column is-8">
        <div class="field">
          <!-- <label for="note_note">{{ label }}</label> -->
          <div class="control is-medium" :class="{ s2t }">
            <textarea
              v-model="innerValue"
              v-bind="$attrs"
              v-on="$listeners"
              class="input is-medium"
            />
            <template v-if="s2t && webkitSpeechRecognition">
              <button type="button" @click="startButton">
                <img
                  v-if="recognizing"
                  src="@/assets/img/mic-animate.gif"
                  alt="Start"
                />
                <img v-else src="@/assets/img/mic.gif" alt="Start" />
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    text: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },

    s2t: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
    webkitSpeechRecognition: null,
    recognition: null,
    recognizing: false,
    ignore_onend: false,
    final_transcript: "",
    start_timestamp: null,
  }),
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
    }),
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    startButton(event) {
      if (this.recognizing) {
        this.recognition.stop();
        return;
      }
      this.final_transcript = "";
      this.recognition.lang = "pl-PL";
      this.recognition.start();
      this.ignore_onend = false;
      this.start_timestamp = event.timeStamp;
    },
  },
  mounted() {
    var two_line = /\n\n/g;
    var one_line = /\n/g;
    // eslint-disable-next-line no-unused-vars
    function linebreak(s) {
      return s.replace(two_line, "<p></p>").replace(one_line, "<br>");
    }
    var first_char = /\S/;
    function capitalize(s) {
      return s.replace(first_char, function (m) {
        return m.toUpperCase();
      });
    }
    if ("webkitSpeechRecognition" in window) {
      this.webkitSpeechRecognition = true;
      // eslint-disable-next-line no-undef
      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = false;
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;

      this.recognition.onstart = () => {
        this.recognizing = true;
      };

      this.recognition.onend = () => {
        this.recognizing = false;
        if (this.ignore_onend) {
          return;
        }
        if (!this.final_transcript) {
          return;
        }
      };

      this.recognition.onresult = (event) => {
        // eslint-disable-next-line no-unused-vars
        var interim_transcript = "";
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.final_transcript += event.results[i][0].transcript;
          } else {
            interim_transcript += event.results[i][0].transcript;
          }
        }
        this.final_transcript = capitalize(this.final_transcript);
        this.innerValue += this.final_transcript;
        // final_span.innerHTML = linebreak(final_transcript);
      };
    }
  },
};
</script>

<style scoped>
.control.s2t {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
