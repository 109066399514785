<template>
  <div v-if="dictionary && Object.keys(dictionary).length > 0" class="ym-whitebg mb-24" style="padding: 16px;margin-bottom: 24px">
    <div v-if="isEdit">
      <ValidationObserver v-slot="{ handleSubmit, errors }">
        <form @submit.prevent="handleSubmit(onOrderNoteSubmit)">
          <div style="margin-bottom: 16px">
            <label for="f_company_full">{{ dictionary['table_order_notes_add_field_label'] }}</label>
            <ValidationProvider rules="required" name="note_note">
              <textarea name="note_note" v-model="order_note.note" class="input is-medium" />
            </ValidationProvider>
            <span v-if="errors['note_note'] && errors['note_note'].length > 0" class="help is-danger">{{ errors['note_note'][0] }}</span>
          </div>
          <div>
            <label for="note_service_time">{{ dictionary['table_order_notes_service_time_field_label'] }} <small>(00:00:00 (hh:mm:ss))</small></label>
            <ValidationProvider rules="" name="note_service_time">
              <input
                name="note_service_time"
                placeholder="00:00:00"
                v-maska="'##:##:##'"
                type="text"
                v-model="order_note.service_time"
                class="input is-medium is-uppercase"
              />
            </ValidationProvider>
          </div>
          <div style="display: flex;align-items: center;margin-top: 16px">
            <div style="display: flex;gap: 8px;margin-left: auto;">
              <button @click="toggleEdit" class="button is-small is-secondary">
                <span>zamknij</span>
              </button>
              <button class="button is-small is-primary">
                <span>zapisz</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else>
      <label style="display: flex;" >{{ dictionary['table_order_notes_note_field_label'] }} dodana przez: {{ note.user.firstname + ' ' + note.user.lastname }} <small style="margin-left: auto;">{{ note.created_at }}</small></label>
      <p>{{ note.note }}</p>
      <div style="display: flex;align-items: center;margin-top: 16px">
        <p v-if="note.service_time !== '00:00:00'">{{ dictionary['table_order_notes_service_time_field_label'] }}: <b>{{ note.service_time }}</b></p>
        <div style="display: flex;gap: 8px;margin-left: auto;">
          <button @click="removeNote" class="button is-small is-secondary">
            <icon name="trash"></icon><span>Usuń</span>
          </button>
          <button @click="toggleEdit" class="button is-small is-secondary">
            <icon name="edit"></icon><span>Edytuj</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import {mapActions, mapGetters} from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

  export default {
    props: {
      note: {
        type: Object,
        required: true,
      },    
    },
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        isEdit: false,
        order_note: {
          note: this.$props.note.note,
          service_time:  this.$props.note.service_time,
        },
      }
    },
    computed: {
      ...mapGetters({
        dictionary: 'dictionary',
      }),
    },
    methods: {
      ...mapActions({
        editOrderNote: 'orders/editOrderNote',
        removeOrderNote: 'orders/removeOrderNote'
      }),  
      toggleEdit() {
        this.isEdit = !this.isEdit
      },
      onOrderNoteSubmit() {
        const payload = {
          data: {
            ...this.order_note, 
            order_id: this.$route.params.id
          },
          id: this.$props.note.id
        }

        this.editOrderNote(payload)
          .then(() => {
            this.$emit('update')
            this.toggleEdit()
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 
      },
      removeNote() {
        if (confirm("Czy na pewno?") === true) {
          this.removeOrderNote(this.$props.note.id)
          .then(() => {
            this.$emit('update')
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 
        }
      }
    }
  }
  </script>
  
  <style scoped></style>
  